.profileCtn {
  display: flex;
  flex: 1 1;
  /* background-color: #f2f2f2; */
  background-color: #e0f7ee;
}

.profileCtn .container-fluid {
  display: flex;
  flex-direction: column;
}

.profileCtn .container-fluid > .row {
  flex: 1 1;
}

.profileGapCol {
  gap: 40px;
}

.profileDetails {
  flex: 1 1;
  padding: 0 15px;
}

.userAvatar {
  width: 150px;
  border-radius: 50%;
}

.userLeftCol {
  text-align: center;
  /* background-color: white; */
  padding: 50px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* max-width: 250px; */
}

.userLeftCol h1 {
  font-size: 24px;
  margin-top: 20px;
}

.userLeftCol h3 {
  font-size: 18px;
  margin-top: 20px;
  font-weight: bold;
  padding: 0 10px;
}

.userLeftCol h4 {
  font-size: 14px;
  padding: 0 10px;
}

.userRightCol {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.userRightCol h1 {
  font-size: 24px;
  font-weight: bold;
}

#file {
  display: none;
}

.uploadButtonCtn {
  display: inline-block;
  margin-top: 30px;
}

.uploadButtonCtn label {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
}

.uploadButtonCtn label i {
  color: rgb(20, 110, 190);
  font-size: 68px;
}

.uploadButtonCtn i,
.uploadButtonCtn span {
  cursor: pointer;
}

.uploadButton {
  display: inline-block;
  background-color: #00ff84;
  padding: 10px 20px;
  cursor: pointer;
}

.profileLinks {
  list-style: none;
  text-align: left;
  margin: 0 auto;
  margin-top: 50px;
  align-self: flex-start;
}

.profileLinks li {
  margin-bottom: 12px;
}

.profileLinks i {
  margin-right: 20px;
  color: #8592a6;
  height: 10px;
  width: 25px;
  font-size: 20px;
}

.profileLinks li a {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 14px;
  color: #8592a6;
  line-height: 35px;
}

.userDetails {
  background-color: white;
  text-align: center;
  padding: 30px 40px;
  /* width: 550px; */
  margin-bottom: 50px;
  margin-top: 50px;
}

.userDetails form {
  text-align: left;
}

/* .userDetails label {
  display: inline-block;
} */

.userDetails input {
  width: 100%;

  background: transparent;
  border: 1px solid #cbcbd0;
  box-shadow: none;
  border-radius: 4px;
  padding: 5px 10px;
  height: 40px;
  margin-bottom: 14px;
}

.userDetails h3 {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 700;
}

.userDetails img {
  width: 100px;
  margin-right: 0px;
}

.imagePreviewOverlay {
  z-index: 2;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  /* top: 50%; */
  margin: auto;
  background-color: rgba(0, 0, 0, 0.85);
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.closeOverlay {
  color: #fff;
  font-size: 25px;
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

.imagePreviewOverlay .ReactCrop {
  max-width: 600px;
  max-height: 60vh;
}

/* .imagePreviewOverlay .ReactCrop div:first-child {
    height: 100%;
} */

.imagePreviewOverlay img {
  width: 100%;
  margin: 0 auto;
}

.imagePreviewOverlay h2 {
  color: #fff;
  margin-bottom: 40px;
}

.imagePreviewOverlay h2,
.imagePreviewOverlay form {
  width: 100%;
  text-align: center;
}

.imagePreviewOverlay button {
  display: inline-block;
  margin: 0 auto;
  margin-top: 40px;
}

.saveChanges {
  padding: 6px 20px;
  border: 1px solid black;
}

.formError {
  color: red;
}

.billingCtn {
  padding: 50px;
  flex: 1;
}

.billingCtn .card {
  margin-bottom: 20px;
}

.billingCtn .card-header {
  font-size: 20px;
}

.billingCtn h2 {
  font-size: 20px;
  margin-top: 50px;
}

.billingCtn h3 {
  font-size: 16px;
  margin-bottom: 20px;
}

.billingCtn .actionButton {
  border: none;
}

.billingCtn h4 {
  font-size: 16px;
}

.userBillingHistoryTitle {
  margin-top: 50px;
}

.billingRow {
  padding: 10px 0;
}

.cancelMembership {
  padding: 6px 20px;
  border: 1px solid black;
}

.myCoursesCtn {
  max-width: 1200px;
  padding: 50px;
}

.myCoursesCtn h1 {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 50px;
}

/* .myCoursesCtn h2 {
  font-size: 18px;
} */

.profileLinks li .activeProfilePage,
.profileLinks li .activeProfilePage i {
  color: #212529;
}

.userBillingCtn th,
.userBillingCtn td {
  padding-left: 15px;
}

.userBillingCtn td {
  padding-bottom: 10px;
}

.changePasswordCtn {
  border: 1px solid grey;
  border-radius: 5px;
  padding: 10px 20px;
  margin-top: 14px;
  margin-bottom: 30px;
}

.lock {
  overflow: hidden;
}

.myCoursesRightCol {
  padding-right: 15px;
  padding-left: 15px;
  flex: 1;
}

@media only screen and (max-width: 1300px) {
  .billingScroll {
    min-width: 900px;
  }

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 10px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
}

.userNameLabel {
  font-weight: 700;
  font-size: 18px;
}

.limitSize {
  max-width: 550px;
  margin: 0 auto;
}

@media only screen and (max-width: 1024px) {
  .myCoursesCtn .courseTitleCtn a {
    font-size: 14px;
  }
}

/* @media only screen and (max-width: 991px) {
  .userLeftCol {
    display: none !important;
  }
} */

/* @media only screen and (max-width: 768px) {
  .userDetails {
    width: 450px;
  }
} */

@media only screen and (max-width: 500px) {
  /* .profileCtn {
        padding-top: 50px;
        padding-bottom: 50px;
    } */

  .billingCtn {
    padding: 10px;
  }

  /* .myCoursesCtn .cardBorder {
        margin-bottom: 30px;
    } */

  .userBillingCtn th {
    min-width: 100px;
  }

  .userBillingCtn th.productName {
    min-width: 200px;
  }

  .userDetails {
    padding: 30px 20px;
  }

  .userDetails img {
    margin: 0;
  }

  .imagePreviewOverlay .ReactCrop {
    width: 300px;
    max-height: 60vh;
  }

  .myCoursesCtn {
    max-width: 1200px;
    padding: 50px 30px;
  }

  .myCoursesCtn h1 {
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 768px) {
  .profileDetails {
    padding: 0;
  }
}

@media only screen and (min-width: 992px) {
  .userLeftCol {
    max-width: 250px;
  }
}
