.forgotPasswordCtn {
  flex: 1 1;
}

.resetInput {
  width: 100%;
  background: transparent;
  border: 1px solid #cbcbd0;
  box-shadow: none;
  border-radius: 4px;
  padding: 5px 10px;
  height: 40px;
  margin-bottom: 10px;
}

.forgotPasswordTitle {
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
}

.forgotPasswordCard {
  margin-top: 70px;
  padding: 30px 30px;
}

.card form {
  width: 60%;
  margin: 0 auto;
}

.forgotPasswordCtn input {
  width: 100%;
}

.forgotPasswordCtn h3 a {
  color: #000;
}

@media (min-width: 500px) {
  .forgotPasswordCard {
    margin-top: 70px;
    padding: 30px 45px;
  }
}
