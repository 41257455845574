.privacyCtn {
  /* background: linear-gradient(to bottom, #e3ffe7 0%, #d9e7ff 100%); */
  flex: 1 1;
  padding-top: 50px;
  padding-bottom: 50px;
}

.privacyMainTitle {
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
}

.privacySecondTitle {
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
}

.privacyCtn ul {
  padding-left: 20px;
}

.divCard {
  background-color: #fff;
  padding: 30px;
}