header {
  background-image: url('../../images/telmo-academy.jpg');
  height: 70vh;
  background-size: cover;
  background-repeat: no-repeat;
  font-family: 'Roboto', sans-serif;
  color: #fff;
  /* position: relative; */
  display: flex;
  flex-wrap: wrap;
  flex: 1 1;
}

.heroTitle {
  width: 100%;
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.heroTitle h1 {
  line-height: 95px;
  letter-spacing: 0px;
  font-weight: 700;
  margin-bottom: 30px;
  font-size: 30px;
  line-height: 40px;
}

.heroTitle p {
  margin-bottom: 30px;
}

.actionButton {
  background-color: #00ff84;
  padding: 10px 30px;
  color: rgb(51, 51, 51);
  font-weight: 700;
  font-size: 13px;
  display: inline-block;
  line-height: 30px;
  text-decoration: none;
  transition: all 0.5s ease-in-out;
  border-radius: 5px;
}

.actionButton:hover {
  text-decoration: none;
  background-color: #00e677;
  color: #000;
}

@media(min-width: 768px) {
  .heroTitle h1 {
    font-size: 45px;
    line-height: 65px;
  }

  .heroTitle p {
    font-size: 25px;
    letter-spacing: -1px;
    margin-bottom: 45px;
  }
}