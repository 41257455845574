/* html,
body {
  height: 100%;
} */

.cardBorder {
  border: 1px solid rgb(229, 229, 229);
  background-color: #fff;
  /* -webkit-box-shadow: 0px 19px 30px -12px rgba(0,0,0,0.49);
  -moz-box-shadow: 0px 19px 30px -12px rgba(0,0,0,0.49);
  box-shadow: 0px 19px 30px -12px rgba(0,0,0,0.49); */
  box-shadow: 0 2px 4px 0 rgba(184, 184, 184, 0.5);
  display: flex;
}

.adminCtn {
  flex: 1 1;
  background-color: #f4f4f4;
  padding-top: 50px;
}

.adminCtn .row {
  /* min-height: calc(100vh - 200px); */
}

.adminCtn .col-3,
.adminCtn .col-9 {
  flex: 1 1;
}

.adminCourseTop {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 80%;
  margin: 0 auto;
}

.adminCourseTop .row {
  width: 100%;
}

.btn a {
  color: #fff;
  text-decoration: none;
}

.separator {
  border-bottom: 2px solid rgb(229, 229, 229);
  width: 100%;
  height: 1px;
  margin: 0 auto;
}

.courseThumbnail {
  display: inline-block;
}

.courseThumbnail img {
  width: 150px;
}

.courseInfoCtn {
  display: flex;
  padding: 0 30px;
  flex-wrap: wrap;
  align-items: center;
}

.courseInfoCtn input {
  margin-right: 10px;
}

.courseInfoCtn > div {
  width: 100%;
}

.courseInfoCtn a {
  font-size: 22px;
  font-weight: bold;
}

.courseInfoCtn a:hover {
  text-decoration: none;
}

.newCoursBtn,
.newCoursBtn:hover {
  text-decoration: none;
  color: #fff;
}
