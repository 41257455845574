.mainContainer {
  flex: 1 1;
}

.cardCtn {
  display: inline-block;
  text-align: left;
  /* padding: 20px 30px; */
}

.cardCtn h1 {
  margin-bottom: 20px;
  font-size: 24px;
}

.cardCtn h4 {
  font-size: 18px;
}

.checkoutSuccessCtn {
  /* text-align: center; */
  flex: 1 1;
  margin-top: 50px;
}

/* .checkoutSuccess {
  padding-top: 50px;
} */

.checkoutSuccess h1 {
  font-weight: bold;
  margin-bottom: 30px;
  /* font-size: 26px; */
}

.checkoutSuccess h4 {
  margin-bottom: 30px;
}

/* .checkoutSuccess h5 {
  margin-top: 30px;
} */
