.adminCtn {
  flex: 1 1;
  background-color: #f4f4f4;
  padding: 50px;
}

.userDetailsRow {
  padding: 20px 0;
}

.adminCtn table {
  table-layout: fixed;
  width: 100%;
}
.adminCtn td {
  width: 20%;
}

.adminCtn th {
  background-color: #999999;
}

.adminCtn td,
.adminCtn th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.userImage {
  max-width: 100%;
}

/* tr:nth-child(odd) {
  background-color: #dddddd;
} */

.userCard {
  padding: 20px 50px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  flex-direction: row;
}

.userColDivider {
  width: 50%;
  margin-bottom: 20px;
}

.card.userCard > h4 {
  width: 100%;
}

.card.userCard tr {
  background-color: initial;
}

.card.userCard > table,
.card.userCard td {
  width: auto;
}

.card.userCard i {
  cursor: pointer;
}
