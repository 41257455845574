.button {
  font-size: 16px;
  border: none;
  padding: 10px 12px;
  border-radius: 5px;
  display: inline-block;
}

.mainBackground {
  background-color: #f2f2f2;
}

.mainTitle {
  color: #333;
  font-family: 'Roboto Slab';
  font-size: 30px;
  font-weight: 700;
  line-height: 40px;
}

.cardSmall {
  max-width: 600px;
  margin: 0 auto;
}

.mainBtn {
  background-color: #1e6091;
  color: #fff;
  transition: all 0.5s ease-in-out;
}

.mainBtn:hover {
  text-decoration: none;
  color: #fff;
  background-color: #164163;
}

.secondBtn {
  background-color: #99d98c;
  color: #000;
  transition: all 0.5s ease-in-out;
}

.secondBtn:hover {
  text-decoration: none;
  color: #000;
}

.actionButton {
  background-color: red;
  color: #fff;
  transition: all 0.5s ease-in-out;
}

.actionButton:hover {
  background-color: rgb(204, 4, 4);
  color: #fff;
}

.width80 {
  width: 80%;
}

.bold {
  font-weight: bold;
}

.btnDisabled {
  background-color: #6c757d;
  opacity: 0.65;
}

.boxShadow {
  box-shadow: 0 2px 4px 0 rgb(184 184 184 / 50%);
}

.ctnPadding {
  padding-top: 50px;
  padding-bottom: 50px;
}

.cardHeaderTitle {
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  margin: 5px 0;
}

.cardSubTitle {
  font-size: 18px;
}

.codex-editor__redactor {
  padding-bottom: 100px !important;
}
