.checkoutSuccessCtn {
  flex: 1 1;
}

.checkoutSuccess {
  padding-top: 50px;
}

.checkoutSuccess h1 {
  font-weight: bold;
  margin-bottom: 30px;
  font-size: 26px;
}

.checkoutSuccess h4 {
  margin-bottom: 30px;
}

.checkoutSuccess h5 {
  margin-top: 30px;
}

.checkoutSuccessBtn {
  background-color: #00ff84;
  color: #000;
  padding: 10px 20px;
  margin-top: 20px;
  display: inline-block;
  transition: all 0.5s ease-in-out;
}

.checkoutSuccessBtn:hover {
  background-color: #06e077;
  text-decoration: none;
  color: #000;
}
