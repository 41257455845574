.messageCtn {
    width: 100%;
    max-width: none;
    margin-top: 30px;
    font-size: .875rem;
    background-color: rgba(255,255,255,.85);
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.1);
    box-shadow: 0 0.25rem 0.75rem rgb(0 0 0 / 10%);
    border-radius: .25rem;
    text-align: left;
}

.messageHeader {
  background-color: #d4edda;
  display: flex;
  align-items: center;
  padding: .25rem .75rem;
  color: #6c757d;
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0,0,0,.05);
  border-top-left-radius: calc(.25rem - 1px);
  border-top-right-radius: calc(.25rem - 1px);
}
.messageHeader svg {
  margin-right: 10px;
}

.messageBody {
  padding: .75rem;
  margin: 0;
}