.container {
  background-color: #fff;
  color: #000;
  position: fixed;
  bottom: 10px;
  right: 10px;
  left: 10px;
  /* width: 300px; */
  padding: 20px;
  border-radius: 5px;
  box-shadow: -2px 7px 29px 0px rgb(0 0 0 / 75%);
}

@media(min-width: 1024px) {
  .container {
    width: 800px;
    margin: 0 auto;
  }
}