.allUsersCtn {
  flex: 1;
  padding-top: 50px;
}

.allUsersCtn tr {
  border-bottom: 1px solid black;
}

.allUsersCtn td,
.allUsersCtn th {
  padding: 15px 0;
}

.allUsersCtn th {
  cursor: pointer;
}

.allUsersCtn td:first-child,
.allUsersCtn th:first-child {
  padding-left: 20px;
}

.allUsersTableDiv {
  display: inline-block;
}

.allUsersTable {
  padding: 30px 50px;
  background-color: #fff;
}

.allUsersTable input {
  margin-right: 10px;
}

.allUsersTable thead tr {
  background-color: #999999;
}

.pagination-ctn {
  display: flex;
  justify-content: center;
}

.pagination-ctn ul {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.pagination-ctn li a {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

.pagination-ctn .disabled a {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-ctn .previous a {
  margin-left: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.pagination-ctn .next a {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
