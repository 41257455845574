.billingCtn {
  padding: 30px 10px;
  flex: 1;
}

.billingCtn .card {
  margin-bottom: 20px;
}

/* .cardTitle {
  font-size: 20px;
} */

.billingCtn h2 {
  font-size: 20px;
  margin-top: 50px;
}

.billingCtn h3 {
  font-size: 16px;
  margin-bottom: 20px;
}

.actionButton {
  border: none;
}

.billingCtn h2 {
  font-size: 20px;
  font-weight: bold;
  padding-left: 5px;
  margin: 0 0 20px 0;
}

.billingCtn h4 {
  font-size: 16px;
}

.spinner {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.userBillingCtn {
  background-color: white;
  border: 2px solid rgba(0, 0, 0, 0.125);
  border-radius: 4px;
  padding: 5px;
  padding-bottom: 10px;
}

.userBillingCtn thead,
.userBillingCtn th {
  border-top: 0;
}

.userBillingCtn th,
.userBillingCtn td {
  padding-left: 15px;
}

.userBillingCtn td {
  padding-bottom: 10px;
  /* background-color: white; */
}

.userBillingCtn th {
  vertical-align: top !important;
  background-color: white;
}

.tableCtn {
  padding: 0;
  /* min-width: 900px; */
}

.tableBilling {
  margin: 0;
}

.myCoursesRightCol {
  padding-right: 15px;
  padding-left: 15px;
  flex: 1 1;
}

.separator {
  border-bottom: 1px solid rgba(34, 51, 84, 0.4);
  width: 100%;
}

.productTag {
  padding: 0px 5px;
  border: 1px solid black;
  display: inline-block;
  background-color: rgba(0, 0, 0, 0.1);
  /* border-radius: 5px; */
}

/* .billingScroll {
  min-width: 900px;
} */

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.paymentLoadingOverlay {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.82);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  display: flex;
  align-items: center;
}

.paymentLoadingCtn {
  width: 80%;
  max-width: 600px;
  margin: 0 auto;
}

.cancelWarning {
  color: red;
  margin: 0 auto;
  text-align: center;
  font-size: 40px;
}

@media only screen and (min-width: 768px) {
  /* .profileCtn {
        padding-top: 50px;
        padding-bottom: 50px;
    } */

  .billingCtn {
    padding: 50px 0;
  }

  /* .myCoursesCtn .cardBorder {
        margin-bottom: 30px;
    } */

  .userBillingCtn th {
    min-width: 100px;
  }

  .userBillingCtn th.productName {
    min-width: 200px;
  }

  .myCoursesCtn {
    max-width: 1200px;
    padding: 50px;
  }
}

@media only screen and (min-width: 992px) {
  .billingCtn {
    max-width: 70%;
  }
}

@media only screen and (min-width: 1200px) {
  .billingCtn {
    max-width: 75%;
  }
}
