.activateCtn {
  /* text-align: center; */
  /* background-color: #f2f2f2; */
  flex: 1 1;
  padding-top: 50px;
}

.cardCtn {
  display: inline-block;
  text-align: left;
  padding: 20px 30px;
}

.cardCtn h1 {
  margin-bottom: 20px;
  font-size: 24px;
}

.cardCtn h4 {
  font-size: 18px;
}
