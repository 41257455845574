.membershipCheckoutCtn {
  padding-top: 20px;
  padding-bottom: 50px;
  /* background: linear-gradient(to bottom, #e3ffe7 0%, #d9e7ff 100%); */
  flex: 1 1;
}

.membershipCardCtn > h1 {
  color: #0a2b4e;
  font-weight: bold;
}

.subTitle {
  font-size: 18px;
}

.membershipCardCtn .MembershipTotal {
  /* font-weight: bold; */
  border: 1px solid #b5b5b5;
  background-color: #fafafa;
  border-radius: 5px;
  padding: 20px 10px;
  margin-top: 35px;
}

.priceDetails {
  display: flex;
  justify-content: space-between;
}

.discountCtn {
  margin-top: 35px;
  margin-bottom: 35px;
  display: flex;
}

.discountCtn input {
  width: 100%;
  height: 100%;
  background: transparent;
  border: 1px solid #cbcbd0;
  box-shadow: none;
  border-radius: 4px;
  padding: 5px 10px;
  height: 40px;
  flex: 2 1;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
}

.discountCtn button {
  padding: 2px 10px;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
}

.membershipPay {
  border-radius: 5px;
  background-color: #39b982;
  color: #fff;
  margin-top: 20px;
  transition: all 0.5s ease-in-out;
  display: inline-block;
  padding: 10px 20px;
  text-decoration: none;
  font-weight: bold;
  margin-bottom: 20px;
  border: none;
}

.membershipPay:hover {
  color: #fff;
}

.membershipCard {
  padding: 20px 20px;
}

.membershipCheckoutTitle {
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  margin: 5px 0;
}

.membershipChargingTime {
  text-align: right;
  margin-top: 10px;
}

.spinner {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.braintree-toggle {
  display: none;
}

.editBillBtn {
  display: inline-block;
  text-decoration: underline;
  cursor: pointer;
}

.stripe_card {
  margin: 1rem 0;
  display: inline-block;
}

.iconsWrapper {
  display: flex;
  position: relative;
}

.paymentIcons {
  width: 120px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
}

.cardDetails {
  display: flex;
  gap: 20px;
}

.card_element {
  border: 1px solid grey;
  border-radius: 3px;
  padding: 10px;
  width: 100%;
  margin: 0 auto;
}

.cardNumber {
  width: 100%;
  margin-bottom: 0;
}

.cardExpiry {
  flex: 1.5 1 auto;
  display: inline-block;
}

.cardCvc {
  flex: 1 1 auto;
  display: inline-block;
}

.saveCard {
  display: flex;
  align-items: baseline;
  margin-bottom: 15px;
}

.saveCard input[type='checkbox'] {
  transform: scale(1.2);
}

.saveCard label {
  margin: 0 10px 0 0;
}

.submitBtn button {
  font-weight: bold;
  display: flex;
  align-items: center;
}

.submitBtn button div {
  margin-right: 10px !important;
  display: inline-block;
}

.submitBtn i {
  margin-right: 10px;
}

.submitBtn .hideSavedCard {
  display: none;
}

.billingAddressCtn {
  padding: 40px 10px;
}

.paymentLoadingOverlay {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.82);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  display: flex;
  align-items: center;
}

.paymentLoadingCtn {
  width: 80%;
  max-width: 600px;
  margin: 0 auto;
}

.errorMessage {
  background-color: #ffc0c0;
  padding: 10px 20px;
}

.checkoutTerms {
  font-size: 14px;
}

.checkoutTerms.textRed {
  text-decoration: underline;
  color: #dc3545;
}

.checkoutTerms.textRed a {
  text-decoration: underline;
  color: #dc3545;
  font-weight: bold;
}

.termsCheckBox {
  width: 20px;
  height: 20px;
}

.paymentError {
  color: red;
  margin: 0 auto;
  text-align: center;
  font-size: 40px;
}

@media (min-width: 500px) {
  .membershipCard {
    padding: 20px 40px;
  }

  .billingAddressCtn {
    padding: 40px 30px;
  }

  .termsCheckBox {
    width: 15px;
    height: 15px;
  }
}
