.card.singleClassCtn .card-body {
  padding: 5px 1.25rem;
}

.singleClassCtn {
  margin-bottom: 20px;
  /* display: flex;
  flex: 1 1; */
  width: 100%;
}

.courseCover {
  width: 300px;
}
