.forgotPasswordCtn {
  flex: 1 1;
  /* background: linear-gradient(to bottom, #e3ffe7 0%, #d9e7ff 100%); */
}

.forgotEmailInput {
  width: 100%;
  background: transparent;
  border: 1px solid #cbcbd0;
  box-shadow: none;
  border-radius: 4px;
  padding: 5px 10px;
  height: 40px;
  margin-bottom: 10px;
}

.forgotPasswordCard {
  margin-top: 70px;
  padding: 30px 30px;
}

.forgotPasswordCard input {
  width: 100%;
}

.forgotPasswordMessage {
  color: #515a5f;
  padding-bottom: 22px;
  font-size: 20px;
  text-align: center;
  font-weight: 700;
  line-height: 28px;
  margin-top: 20px;
  margin-bottom: 0;
  padding-bottom: 0;
}

.forgotTitle {
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
}

.fgtPasswordButtons {
  margin-top: 20px;
  display: flex;  
  justify-content: center;
  margin-bottom: 50px;
}

.fgtSeparator {
  margin: 0 20px;
}

@media(min-width: 500px) {
    .forgotPasswordCard {
        padding: 30px 45px;
    }
}
