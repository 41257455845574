.cardBorder {
  border: 1px solid rgb(229, 229, 229);
  background-color: #fff;
  /* -webkit-box-shadow: 0px 19px 30px -12px rgba(0,0,0,0.49);
  -moz-box-shadow: 0px 19px 30px -12px rgba(0,0,0,0.49);
  box-shadow: 0px 19px 30px -12px rgba(0,0,0,0.49); */
  box-shadow: 0 2px 4px 0 rgba(184, 184, 184, 0.5);
  position: relative;
}

.courseThumbnail img {
  width: 100%;
}

.courseTitleCtn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 22px 15px;
  height: 70px;
  text-align: center;
}

.courseTitleCtn a {
  color: #000;
  font-size: 16px;
  font-weight: 600;
}
.courseTitleCtn a:hover {
  text-decoration: none;
}

.courseTitle {
  font-family: 'Roboto Slab';
  text-decoration: none;
  color: #333;
  font-size: 16px;
  line-height: 25px;
  font-weight: 700;
  display: block;
}

.courseTitle:hover {
  text-decoration: none;
}

.separator {
  border-bottom: 2px solid rgb(229, 229, 229);
  width: 80%;
  height: 1px;
  margin: 0 auto;
}

.priceCtn {
  padding: 14px 20px 10px 20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #fff;
}

.priceCtn svg {
  color: rgb(0, 0, 0);
  margin-right: 5px;
  font-size: 20px;
}

.studentNumbers {
  font-size: 14px;
  display: inline-block;
  color: #999;
  line-height: 30px;
  font-weight: 400;
}

.price {
  font-size: 16px;
  font-weight: 700;
  color: #000;
  /* background-color: orange; */
  padding: 2px 5px;
  border-radius: 5px;
}

.free {
  display: inline-block;
  background-color: orange;
  padding: 2px 10px;
}

.courseProgressBorder {
  padding: 0px 20px 10px 20px;
}

.courseProgressCtn {
  width: 100%;
  background-color: rgb(224, 224, 224);
  height: 5px;
  margin-bottom: 5px;
}

.blockCourse {
  background-color: rgba(128, 128, 128, 0.479);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.blockCourse {
  cursor: not-allowed;
}
