.checkoutCtn {
  /* background: linear-gradient(to bottom, #e3ffe7 0%, #d9e7ff 100%); */
  background-color: #f2f2f2;
  flex: 1 1;
  padding-top: 20px;
}

.courseDelete {
  cursor: pointer;
  padding: 10px 20px;
}

.paymentCtn,
.billingAddressCtn {
  background-color: white;
  padding: 40px 20px;
}

.spinner {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.paymentCtn h1,
.billingAddressCtn h1 {
  font-size: 22px;

  border-bottom: 1px solid lightgray;
  padding-bottom: 20px;
  margin-bottom: 20px;
  font-weight: 700;
}

.subTitle {
  font-size: 18px;
}

.checkoutCourse {
  display: inline-block;
  font-size: 16px;
}

.courseInCheckout {
  border-bottom: 1px solid lightgray;
  display: flex;
  justify-content: space-between;
  padding: 15px 0px 15px 20px;
  background-color: #fff;
  margin-left: -20px;
  margin-right: -20px;
  margin-bottom: 10px;
  box-shadow: 0 2px 4px 0 rgb(184 184 184 / 50%);
}

.paperGray {
  background-color: #e8e8e8;
  padding: 40px 20px;
  width: 100%;
  height: 100%;
}

.basketTitle {
  font-size: 22px;
  border-bottom: 1px solid lightgray;
  padding-bottom: 20px;
  margin-bottom: 20px;
  font-weight: 700;
}

.basketTitle + h1 {
  font-size: 20px;
}

.checkoutPrice {
  padding: 15px 0;
  /* display: flex; */
  /* justify-content: flex-end; */
  text-align: right;
}

del {
  margin-right: 5px;
}

.braintree-toggle {
  margin-bottom: 10px;
}

.paymentLoading .braintree-toggle {
  display: none;
}

.couponInputCol {
  flex: 2;
}

.couponInputCol input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

/* .paperGray, */
.paymentCtn,
.billingAddressCtn {
  box-shadow: 0 0.25rem 0.75rem rgb(0 0 0 / 10%);
}

.searchCourses {
  align-self: center;
}

.searchCourses,
.searchCourses:hover {
  color: #fff;
  text-decoration: none;
}

.defaultCheckboxCtn input {
  width: 16px;
  height: 16px;
  margin-right: 5px;
}

.editBillBtn {
  display: inline-block;
  text-decoration: underline;
  cursor: pointer;
  margin-bottom: 20px;
}

.stripe_card {
  margin: 1rem 0;
  display: inline-block;
}

.card-number {
  margin-top: 2rem;
}

.iconsWrapper {
  display: flex;
  position: relative;
}

.paymentIcons {
  width: 120px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
}

.cardDetails {
  display: flex;
  gap: 20px;
}

.card_element {
  border: 1px solid grey;
  border-radius: 3px;
  padding: 10px;
  width: 100%;
  margin: 0 auto;
}

.cardNumber {
  width: 100%;
  margin-bottom: 0;
}

.cardExpiry {
  flex: 1.5 1 auto;
  display: inline-block;
}

.cardCvc {
  flex: 1 1 auto;
  display: inline-block;
}

.saveCard {
  display: flex;
  align-items: baseline;
  margin-bottom: 15px;
}

.saveCard input[type='checkbox'] {
  transform: scale(1.2);
}

.saveCard label {
  margin: 0 10px 0 0;
}

.submitBtn button {
  font-weight: bold;
  display: flex;
  align-items: center;
}

.submitBtn button div {
  margin-right: 10px !important;
  display: inline-block;
}

.submitBtn i {
  margin-right: 10px;
}

.submitBtn .hideSavedCard {
  display: none;
}

.paymentLoadingOverlay {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.82);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  display: flex;
  align-items: center;
}

.paymentLoadingCtn {
  width: 80%;
  max-width: 600px;
  margin: 0 auto;
}

.courseImage {
  width: 50px;
}

.finalPrice {
  font-weight: bold;
  padding: 10px 0px;
  /* background-color: #fff; */
  display: inline-block;
  /* text-decoration: underline; */
  font-size: 18px;
}

.courseImageMobile {
  display: flex;
  align-items: center;
}

.courseItemPrice {
  display: none;
}

.courseDeleteCtn {
  display: flex;
  align-items: center;
}

.paymentCtn .totalPriceCard {
  font-size: 18px;
  padding-bottom: 10px;
}

.errorMessage {
  background-color: #ffc0c0;
  padding: 10px 20px;
}

.paymentError {
  color: red;
  margin: 0 auto;
  text-align: center;
  font-size: 40px;
}

.checkoutTerms {
  font-size: 14px;
}

.checkoutTerms.textRed {
  text-decoration: underline;
  color: #dc3545;
}

.checkoutTerms.textRed a {
  text-decoration: underline;
  color: #dc3545;
  font-weight: bold;
}

.termsCheckBox {
  width: 20px;
  height: 20px;
}

@media (min-width: 500px) {
  .termsCheckBox {
    width: 15px;
    height: 15px;
  }
}

@media (min-width: 768px) {
  .paymentCtn,
  .billingAddressCtn {
    padding: 40px 30px;
    height: 100%;
  }

  .paperGray {
    padding: 40px 30px;
    height: 100%;
  }

  .courseInCheckout {
    padding: 15px 10px 15px 30px;
  }

  .couponCtn button {
    margin-top: 0;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }

  .courseItemPrice {
    display: inline-block;
  }

  .courseImage {
    width: 70px;
  }

  .courseNameCtn {
    padding-right: 15px;
  }
}
