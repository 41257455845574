.courseLesson {
  display: flex;
  flex-direction: column;
  flex: 1 1;
}

.classesCol {
  flex: 1 1;
  display: flex;
  flex-wrap: wrap;
}

.vp-center {
  align-items: flex-start !important;
}

.courseLesson h1 {
  font-size: 20px;
  /* background-color: #9aacd5; */
  color: rgb(232, 232, 232);
  /* padding: 10px 20px; */
  margin: 0;
  color: #000;
}

.courseLesson .col-8 h1 {
  background-color: orange;
  text-align: center;
  color: rgb(33, 35, 56);
}

.courseLesson .col-4 {
  display: flex;
  flex-direction: column;
}

.courseLesson .col-4,
.courseLesson .col-8 {
  padding: 0;
}

.courseCurriculum {
  background-color: #f2f2f2;
  margin: 0 0 30px;
  padding: 5px 20px;
  font-size: 16px;
  border-bottom: 1px solid grey;
}

.lessonsCtn {
  background: #f0f0f0;
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: 50px;
}

.lessonsCtn > div {
  background: #f0f0f0;
  transition: all 0.3s ease-in-out;
  width: 90%;
  margin: 0 auto;
}

.lessonsCtn > div:hover {
  background: #dcdcdc;
}

.lesson {
  /* background: #f0f0f0; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 0px 10px 10px;
  border-bottom: 1px solid #c0c0c0;
}

.stripe {
  background: #dcdcdc;
}

.lessonLink {
  text-decoration: none;
  color: rgb(33, 37, 41);
  align-items: center;
  padding: 0;
  flex: 1;
}

.lessonLink:hover {
  text-decoration: none;
}

.lessonActiveCtn .lesson {
  background: #c0c0c0;
  border: 1px solid #929292;
}

.lessonActive p {
  font-weight: bold;
}

.lessonTime {
  display: block;
}

.lesson p {
  margin: 0;
}

.lesson i {
  margin-right: 15px;
}

.lessonComplete {
  width: 20px;
  height: 20px;
  margin-right: 20px;
  border: none;
  background-color: transparent;
}

.lessonComplete:hover {
  cursor: pointer;
}

.lessonComplete i {
  font-size: 20px;
}

.currentLessonTitle {
  padding: 30px 0px 10px 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 90%;
  margin: 0 auto;
  margin-bottom: 20px;
  border-bottom: 1px solid black;
}

.currentLessonTitle i {
  margin-right: 10px;
}

.currentLessonTitle p {
  margin: 0;
}

.videoIframe {
  background-color: lightblue;
  margin: 0 auto 30px;
  text-align: center;
  width: 90%;
  border: 1px solid rgb(161, 161, 161);
  border-radius: 5px;
}

.videoIframe .vp-center {
  align-items: start !important;
  color: blue !important;
}

.courseLessonCardCtn {
  background: #f0f0f0;
}

.courseLessonCard {
  width: 90%;
  background-color: #fff;
  border-radius: 5px;
  margin: 20px auto;
  padding: 25px 20px;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 35%);
}

.courseLinksCtn h1 {
  font-size: 24px;
}

.courseLessonCard p {
  margin-bottom: 0;
}

.courseProgressCtn {
  width: 100%;
  background-color: rgb(224, 224, 224);
  height: 5px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.courseLinksCtn {
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  margin-bottom: 0px;
  order: 1;
}

.courseLinksCtn.backup {
  position: absolute;
}

.courseMainVideoCtn {
  flex: 1 1;
  height: auto;
  width: 100%;
}

.nextPrevButtonsCtn {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  background-color: #c0c0c0;
  text-align: center;
  color: #fff;
  font-size: 16px;
}

.nextPrevButtonsCtn a {
  width: 50%;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  padding: 10px 20px;
}

.nextPrevButtonsCtn a:first-child {
  border-right: 1px solid black;
}

.nextPrevButtonsCtn i {
  margin-left: 10px;
}

.nextPrevButtonsCtn a:first-child i {
  margin-right: 10px;
}

.nextPrevButtonsCtn .buttonDisabled {
  pointer-events: none;
  opacity: 0.3;
}

.commentsCtn {
  display: flex;
  margin: 0 auto;
  width: 90%;
  align-items: center;
  flex-direction: column;
  order: 2;
}

.commentsBtnCtn {
  display: flex;
  justify-content: center;
}

.commentsTitle {
  font-size: 22px;
}

.allComments {
  text-align: left;
  width: 95%;
  margin: 0 auto;
}

.customEditor {
  border: 1px solid black;
}

.customEditorCtn {
  width: 95%;
  margin: 0 auto;
}

.commentError {
  background-color: #d4edda;
}

.userAvatar {
  width: 35px;
}

@media only screen and (min-width: 769px) {
  .courseLinksCtn {
    order: 0;
    width: 350px;
    margin-bottom: 0px;
  }

  .nextPrevButtonsCtn h1 {
    padding: 13px 5px;
  }

  .lessonsCtn {
    margin-bottom: 0px;
  }
}

@media only screen and (min-width: 1024px) {
  .allComments,
  .customEditorCtn {
    max-width: 1100px;
  }
}
