@import url('https://fonts.googleapis.com/css?family=Roboto:400,600,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto+Slab:400,600,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Roboto', sans-serif;
}

html {
  height: 100%;
  font-size: 16px;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

#root > .container {
  flex: 1;
  min-height: 567px;
}
