.title {
  padding-top: 42px;
  padding-bottom: 20px;
  color: #333;
  font-family: 'Roboto Slab';
  font-size: 30px;
  line-height: 40px;
  font-weight: 700;
}

.actionButton {
  background-color: #00ff84;
  padding: 10px 30px;
  color: rgb(51, 51, 51);
  font-weight: 700;
  font-size: 13px;
  display: inline-block;
  line-height: 30px;
  text-decoration: none;
  transition: all 0.5s ease-in-out;
  border-radius: 5px;
}
