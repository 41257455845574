.myCoursesTitle {
  font-size: 22px;
  font-weight: bold;
}

.myCoursesSubTitle {
  font-size: 16px;
}

/* .myCoursesRightCol {
  margin-top: 50px;
  margin-bottom: 50px;
  padding-right: 15px;
  padding-left: 15px;
  flex: 1 1;
} */

.myCoursesCtn {
  padding-top: 30px;
  padding-bottom: 30px;
}

.cardBorder {
  border: 1px solid rgb(229, 229, 229);
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgb(184 184 184 / 50%);
}

.separator {
  border-bottom: 1px solid rgba(34, 51, 84, 0.4);
  width: 100%;
}

@media only screen and (min-width: 500px) {
  .myCoursesCtn {
    padding: 50px 0;
    flex: 1 1;
  }

  .myCoursesRightCol {
    margin-top: 0;
  }
}
