.contactCtn {
  max-width: 600px;
  margin: 0 auto;
}

.formCtn {
  background-color: #fff;
  padding: 40px 30px;
}

.success {
  padding: 10px 20px;
  background-color: #e8f0fe;
}
